import React from "react"
import { Link } from "gatsby"

import useSiteMetadata from "gatsby-theme-garden/src/use-site-metadata"
import DarkModeToggle from "gatsby-theme-garden/src/components/dark-mode-toggle"
import GraphButton from "gatsby-theme-garden/src/components/graph-button"
import { Search } from "gatsby-theme-garden/src/components/search"
import SupradaLogo from "./supradaLogo"
import BentoLogo from "./bentoLogo"

import "gatsby-theme-garden/src/components/header.css"
import "./logos.css"

const Header = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <header>
      <Link to="/">
        <div className="logo-container">
          <BentoLogo fill={"#000000"} height={40} width={40} />
          <h3 className="logo-text">{siteMetadata.title}</h3>
        </div>
      </Link>
      <div className="controls">
        <Search />
        <GraphButton />
        <div className="logo-container">
          <a
            href="https://suprada.com"
            alt="Suprada.com"
            title="Go to suprada.com"
          >
            <SupradaLogo fill={"#3da1ff"} />
          </a>
        </div>
        <DarkModeToggle />
      </div>
    </header>
  )
}

export default Header
