import React from "react"

const BentoLogo = ({ height = 30, width = 24, fill = "#000000" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 275.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2340 2877 c-13 -7 -29 -24 -34 -39 -11 -30 148 -2363 164 -2412 20
-60 88 -76 131 -30 21 23 24 34 22 94 -3 76 -149 2190 -159 2289 -9 96 -56
133 -124 98z"
        />
        <path
          d="M2161 2604 c-21 -27 -21 -28 -21 -1233 0 -1199 0 -1207 20 -1229 11
-12 31 -24 44 -28 28 -7 81 18 90 41 3 9 6 560 6 1224 l0 1208 -23 21 c-34 32
-89 30 -116 -4z"
        />
        <path
          d="M294 2356 c-85 -27 -145 -84 -172 -165 -9 -24 -12 -237 -12 -760 l0
-726 25 -53 c27 -58 61 -94 120 -126 40 -21 42 -21 780 -21 592 0 748 3 778
13 48 17 92 54 125 106 l27 41 9 695 c9 759 7 823 -38 890 -36 54 -96 96 -157
109 -77 17 -1431 14 -1485 -3z m366 -596 l0 -440 -190 0 -190 0 -1 23 c0 12 0
200 1 417 1 388 1 395 22 417 21 22 28 23 190 23 l168 0 0 -440z m1121 421
l24 -19 0 -419 0 -418 -242 -3 -243 -2 0 128 c0 209 -33 287 -143 342 -59 29
-64 30 -209 30 l-148 0 0 190 0 190 469 0 c447 0 470 -1 492 -19z m-652 -557
c19 -24 21 -40 21 -165 l0 -139 -165 0 -165 0 0 165 0 165 144 0 c144 0 145 0
165 -26z m-139 -709 l0 -245 -333 0 c-426 0 -394 -25 -382 298 l8 192 353 0
354 0 0 -245z m820 35 c0 -220 -7 -259 -45 -274 -9 -3 -151 -6 -316 -6 l-299
0 0 245 0 245 330 0 330 0 0 -210z"
        />
      </g>
    </svg>
  )
}

export default BentoLogo
