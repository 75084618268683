import React from "react"
import Reference from "gatsby-theme-garden/src/components/reference"
import { sortBy } from "lodash"
import "gatsby-theme-garden/src/components/references-block.css"

const ReferencesBlock = ({ references }) => {
  if (!references.length) {
    return null
  }
  const sortedReferences = sortBy(references, ["slug"])

  return (
    <div className="references-block">
      <h3>Referred in:</h3>
      <div>
        {sortedReferences.map(ref => (
          <Reference node={ref} key={ref.id} />
        ))}
      </div>
    </div>
  )
}

export default ReferencesBlock
