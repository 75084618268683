import React from "react"
import "./footer.css"

const Footer = () => {
  const today = new Date().toISOString().slice(0, 10)

  return (
    <footer>
      <div className="footer">
        <ul className="footer-list">
          <li className="footer-list-item">Copyright © 2020. Suprada Urval</li>
          <li className="footer-list-item">Updated on: {today}</li>
          <li className="footer-list-item">
            Built using:{" "}
            <a href="https://github.com/mathieudutour/gatsby-digital-garden">
              Gatsby Theme Garden
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
