import React from "react"

const SupradaLogo = ({ height = 30, width = 24, fill = "#000000" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 400.000000 399.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,399.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M513 3785 c-130 -35 -229 -113 -284 -223 l-34 -67 -3 -1125 c-2
-1016 -1 -1129 14 -1175 24 -73 69 -139 128 -186 112 -90 185 -109 418 -109
l167 0 3 -324 3 -324 23 -25 c30 -32 63 -40 111 -27 25 6 193 127 496 355
l458 345 701 0 c761 0 769 1 878 56 80 41 150 113 189 197 l34 72 3 1114 c2
1096 2 1116 -18 1175 -37 111 -134 211 -250 259 l-55 22 -1465 2 c-1238 2
-1473 0 -1517 -12z m2918 -195 c73 -14 128 -55 162 -120 l27 -54 0 -1031 c0
-660 -4 -1052 -10 -1086 -13 -69 -51 -116 -120 -151 l-54 -28 -726 0 c-495 0
-737 -3 -758 -11 -17 -6 -199 -138 -404 -294 l-373 -282 -5 256 c-4 223 -7
259 -23 282 -30 45 -53 49 -290 49 -126 0 -239 5 -260 11 -49 13 -115 73 -138
124 -18 38 -19 96 -19 1103 0 938 2 1067 16 1100 28 67 90 118 164 133 57 11
2750 10 2811 -1z"
        />
        <path
          d="M1320 2980 c-185 -26 -296 -105 -351 -249 -17 -45 -20 -71 -17 -136
10 -177 91 -249 348 -309 294 -68 348 -89 366 -146 24 -72 -22 -147 -103 -169
-49 -14 -138 -14 -201 0 -71 15 -127 65 -143 129 l-13 50 -102 0 c-56 0 -116
-3 -134 -6 l-32 -7 5 -54 c17 -167 160 -294 363 -323 220 -31 415 15 528 125
102 99 131 244 73 370 -55 119 -118 156 -364 215 -301 72 -323 84 -323 176 0
39 5 51 33 79 42 42 103 59 182 51 87 -9 145 -41 172 -96 12 -25 24 -53 27
-62 5 -16 19 -18 113 -18 152 0 155 2 147 59 -14 103 -81 204 -169 256 -100
58 -263 84 -405 65z"
        />
        <path
          d="M2080 2550 c0 -427 6 -500 52 -597 75 -160 320 -241 570 -189 131 28
245 110 285 204 41 96 43 124 43 560 l0 422 -125 0 -125 0 0 -352 c0 -480 -9
-547 -83 -602 -25 -18 -42 -21 -130 -21 -91 0 -106 3 -135 23 -18 13 -42 42
-55 65 -22 41 -22 50 -25 465 l-3 422 -135 0 -134 0 0 -400z"
        />
      </g>
    </svg>
  )
}

export default SupradaLogo
